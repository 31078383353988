// prettier-ignore
export default {
  "-0.10 Very Low": "-0.10 Very Low",
  "% Mapa alertas": "% Warning map",
  "% Media Fosforo": "% phosphorus media",
  "% Media Nitrogeno": "% Nitrogen media",
  "% Media Potasio": "% potassium media",
  "% resiembra": "% replanting",
  "0.10 Low": "0.10 Low",
  "0.20 Very Low": "0.20 Very Low",
  "0.25 Very Low": "0.25 Very Low ",
  "0.30 Low": "0.30 Low",
  "0.30 Medium": "0.30 Medium",
  "0.40  Low": "0.40  Low",
  "0.40 Medium": "0.40 Medium",
  "0.45 High": "0.45 High",
  "0.50 High": "0.50 High",
  "0.55 Medium": "0.55 Medium",
  "0.65 Very High": "0.65 Very High",
  "0.70 High": "0.70 High",
  "0.85 Very High": "0.85 Very High",
  "abonado de cobertera": "Cover Fertilization",
  "abonado de fondo": "Basic Fertilization",
  "abonado de fondo ": "Basic Fertilization",
  "abonado dosificado": "Abonado Dosificado",
  "abonado tasa fija": "Fixed Rate Fertilization",
  "abonado zonificado": "Abonado Zonificado",
  "accumulated vegetative growth": "Accumulated Vegetative Growth",
  "Acre": "Acre",
  "active": "active",
  "Add at least one recipient (+)": "Add at least one recipient (+)",
  "Add Field Button": "Add Field Button",
  "Add Field Tutorial": "Tutorial: Adicionar campos",
  "add location": "add location",
  "Add new sample": "Add new sample",
  "add photo": "add photo",
  "added to pending": "added to pending",
  "Advanced": "Advanced",
  "advanced season parameters": "advance season parameters",
  "afección": "Afección",
  "afección extensivo": "Afección Extensivo",
  "Affected Surface": "Affected Surface",
  "After filling the form, you can draw the field": "Depois de preencher o formulário, você pode desenhar o campo",
  "agrodelivery": "AgroDelivery",
  "all": "all",
  "ALMENDRA": "ALMOND",
  "alto estrés hídrico": "High water stress",
  "alto pot prod": "High Pot Prod",
  "ambiente": "Ambient",
  "An error occured": "An error occured",
  "An error occurred from the server. Please try again": "An error occurred from the server. Please try again",
  "And that's it!": "And that's it!",
  "anexo información": "Anexo Información",
  "Applied filters": "Applied filters",
  "Apply": "Apply",
  "apply date filter": "Apply date filter",
  "Apply selection": "Apply selection",
  "Are you sure to close the editor?": "Are you sure to close the editor?",
  "Are You sure?": "Are you sure?",
  "area": "area",
  "Area": "Area",
  "ATR": "Total Recoverable Sugar",
  "author": "Author",
  "Automatic harvest": "Automatic harvest",
  "auxiliary files": "Auxiliary files",
  "back": "back",
  "bajo estrés hídrico": "Low Water Stress",
  "bajo pot prod": "Low Pot Prod",
  "Base temperature": "Base temperature",
  "baseLayer": "Base layer",
  "Basic": "Basic",
  "basic season parameters": "basic season parameters",
  "bipolaris_sacchari_g1": "bipolaris sacchari \"eye spot\" - g1",
  "bipolaris_sacchari_g2": "bipolaris sacchari \"eye spot\" - g2",
  "bipolaris_sacchari_g3": "bipolaris sacchari \"eye spot\" - g3",
  "bipolaris_sacchari_g4": "bipolaris sacchari \"eye spot\" - g4",
  "bipolaris_sacchari_g5": "bipolaris sacchari \"eye spot\" - g5",
  "Both first and last points of the slice line must be outside of the polygon/circle": "Both first and last points of the slice line must be outside of the polygon/circle",
  "Buried": "Buried",
  "calendar": "Calendar",
  "calibre": "Calibre",
  "camera error message": "Looks like there is a permissions issue with camera",
  "camera_unavailable": "camera unavailable",
  "Can't modify field with active planet subscription": "Can't modify field with active planet subscription",
  "cancel": "Cancel",
  "cane_num": "Cane number",
  "cantidad": "Quantity",
  "capa base": "Base layer",
  "Capture screenshot": "Capture screenshot",
  "Change opacity": "Change opacity",
  "Choose your layer": "Choose your layer",
  "Choose your tool": "Choose your tool",
  "Circle Drawing": "Circle Drawing",
  "class": "Class",
  "Class values must be numerical": "Class values must be numerical",
  "classes": "Classes",
  "classes number": " Classes number",
  "clear": "Clear",
  "Clear all": "Clear all",
  "clear form": "clear form",
  "clear selected fields": "Clear selected fields",
  "Clicking this button, you can toggle the visibility of the selected layer.": "Clicking this button, you can toggle the visibility of the selected layer.",
  "clientDeviation": "Client's deviation",
  "Close": "Close",
  "Close editor": "Close editor",
  "Close selection": "Close selection",
  "Close the temporal Comparison": "Close temporal Comparison",
  "close_selected": "Close selected",
  "closed": "Closed",
  "cloud": "Cloud",
  "Cloud Coverage": "Cloud Coverage",
  "comment": "comment",
  "comp. temporal target": "Comp. Temporal Target",
  "comp. temporal variabilidad": "Comp. Temporal Variabilidad",
  "comparación de conteo": "Count comparison (%)",
  "comparación de estado hídrico": "Water status comparison (%)",
  "comparación de estado hídrico drone": "Water status comparison  - Drone (%)",
  "comparación de estado hídrico sat": "Water status comparison  - SAT (%)",
  "comparación de estimación de producción": "Production estimate comparison (%)",
  "comparación de fósforo": "Phosporus comparison (%)",
  "comparación de mapa de alertas": "Warning map comparison (%)",
  "comparación de mapa de faltas": "Faults map comparison (%)",
  "comparación de media de conteo": "Count mean comparison (%)",
  "comparación de media de estado hídrico": "Water status mean comparison (%)",
  "comparación de media de fósforo": "Phosporus mean comparison (%)",
  "comparación de media de mapa de alertas": "Warning map mean comparison (%)",
  "comparación de media de mapa de faltas": "Faults map mean comparison (%)",
  "comparación de media de nitrógeno": "Nitrogen mean comparison (%)",
  "comparación de media de potasio": "Potassium mean comparison (%)",
  "comparación de media de producción total": "Total production mean comparison (%)",
  "comparación de media de rendimiento graso": "Fat performance mean comparison (%)",
  "comparación de medias de estimación de producción": "Production estimate mean comparison (%)",
  "comparación de medias de ndvi": "NDVI mean comparison (%)",
  "comparación de medias de proteína": "Protein mean comparison (%)",
  "comparación de medias de variabilidad": "Variability mean comparison (%)",
  "comparación de ndvi": "NDVI comparison (%)",
  "comparación de nitrógeno": "Nitrogen comparison (%)",
  "comparación de nitrógeno relativo": "Relative nitrogen comparison(%)",
  "comparación de potasio": "Potassium comparison (%)",
  "comparación de producción total": "Total production comparison (%)",
  "comparación de proteína": "Protein comparison (%)",
  "comparación de rendimiento graso": "Fat performance comparison (%)",
  "comparación de sacarosa": "Saccharose Comparison",
  "comparación de variabilidad": "Variability comparison (%)",
  "confirm": "Confirm",
  "Congratulations! Your field has been created.": "Congratulations! Your field has been created",
  "Congratulations! Your field has been edited.": "Congratulations! Your field has been edited",
  "Contact": "Contact",
  "Contenido de sacarosa ( % )": "Sucrose level ( % )",
  "conteo - pdf rgb": "count - pdf rgb",
  "conteo - shps": "count - shps",
  "conteo de individuos": "Conteo de Individuos",
  "conteo lechugas": "Conteo Lechugas",
  "continue": "continue",
  "Cookies and Privacy": "Cookies and Privacy",
  "CORN": "CORN",
  "correction": "Correction",
  "Corte nitrogeno": "nitrogen slice",
  "cost": "Cost",
  "count (units)": "Count (Units)",
  "Counting": "Counting",
  "create": "create",
  "Create New Field": "Create new field",
  "CREATE_RANGES": "Based on the histogram of the image, create ranges with a percentage of similar data in each range.",
  "created": "Created",
  "createFieldForm_create_button": "Create Field",
  "createFieldForm_draft_button": "Save Draft",
  "createFieldForm_info_message": "Saving as a draft allows you to store data temporarily on this device, so you can edit or add geometry before saving to Layers.",
  "crecimiento cultivo": "Crop growth",
  "Crop": "Crop",
  "Crop type": "Crop type",
  "CROP_INSPECTION": "Crop inspection",
  "cultive": "Crop",
  "Cultivo": "Crop",
  "current season": "current season",
  "current selection could take a moment to process": "The current date range has been increased, the request could take a moment to be processed",
  "Customer": "Customer",
  "customerProductionEstimation": "Client's production estimation",
  "customers": "Customer",
  "cut": "Cut",
  "Cutter": "Cutter",
  "dashboard": "Dashboard",
  "date": "Date",
  "DD/MM/YYYY": "DD/MM/YYYY",
  "delete": "delete",
  "Delete": "Delete",
  "Delete downloaded report": "Delete downloaded report",
  "delete downloaded sample": "delete downloaded sample",
  "Delete field": "Delete Field",
  "delete layers?": "delete layers?",
  "delete sample": "delete sample",
  "delete sample edition": "delete sample edition",
  "delete_geometries_action_button": "Delete",
  "delete_geometries_alert_message": "Are you sure you want to delete geometry?",
  "delete_geometries_alert_title": "Delete geometry",
  "DeleteField": "The field has been deleted",
  "Deliverables tutorial": "Tutorial: transmissões",
  "Description": "Description",
  "Deselect": "Deselect",
  "deselect all": "Deselect all",
  "deviation": "Deviation",
  "Did you forget your password ?": "Did you forget your password ?",
  "discard_changes_alert_message": "You have unsaved changes. Are you sure you want to discard them and leave this screen?",
  "discard_changes_alert_title": "Discard Changes?",
  "discard_changes_cancel_button": "Cancel",
  "discard_changes_discard_button": "Discard",
  "disponible en visor": "Available in viewer",
  "do you want to delete": "do you want to delete",
  "documentation": "Documentation",
  "don't show again": "don't show again",
  "Done": "Done",
  "dosificación genérica": "Generic dosage",
  "Dosis Media Cliente": "Customer average dose",
  "Dosis media Propuesta": "Proposed average dose",
  "dosis riego": "Irrigation Dose",
  "Download": "Download",
  "download failed": "The following downloads could not be completed",
  "Download list": "Download list",
  "Download selected fields samples": "Download selected fields samples",
  "download selected geometries": "Download selected geometries",
  "download values": "Download values",
  "downloaded": "downloaded",
  "downloading layers": "downloading layers",
  "downloads": "Downloads",
  "Drag and drop your layers to arrange them in the list.": "Drag and drop your layers to arrange them in the list.",
  "Drag Fields": "Drag Fields",
  "Drag your layers": "Arrastrar suas camadas",
  "Draw Field": "Draw field",
  "dron": "Drone",
  "Dron sectores": "Dron sectors",
  "drop here": "drop here",
  "Dry": "Dry",
  "dry matter": "Dry matter",
  "dryMatterField": "Dry matter",
  "dryMatterLaboratory": "Dry matter laboratory",
  "dsm": "DSM",
  "earliest harvest date": "Earliest harvest date",
  "edit": "edit",
  "Edit Field": "Edit Field",
  "Edit geometry": "Edit geometry",
  "edit range": "Edit range",
  "Edit values": "Edit values",
  "ej 100": "Ex 100",
  "End Date": "End date",
  "Equal Interval": "Equal Interval",
  "Equidistant ranges": "Equidistant ranges",
  "error fetching classes": "The sky is too cloudy on the selected date. Adjust the calendar's cloud filter to find a clearer day.",
  "estado hídrico": "Water status",
  "estado hídrico (ndmi)": "Hydric Status (NDMI)",
  "estado hidrico sat": "NDRE",
  "estimación": "Estimación",
  "estimación producción": "Production estimate",
  "Estimacion produccion ( Tn / Ha )": "Production estimate ( Tn / Ha )",
  "estimación producción cualitativa": "Estimación Producción Cualitativa",
  "estimación proteína": "Estimación Proteína",
  "Estimacion proteina ( % )": " protein estimate ( % )",
  "estimación sacarosa": "Estimated sucrose",
  "estimación sacarosa ": "Estimated sucrose",
  "estimación sacarosa cualitativa": "Estimación Sacarosa Cualitativa",
  "Estimacion Total ( Kg )": "total estimate ( Kg )",
  "estimation gauging": "Estimation Gauging",
  "estrategia de dosificacion": "Dosing strategy",
  "estrés hídrico (lws)": "Water Stress (LWS)",
  "evolución de NDVI": "NDVI Evolution",
  "evolution": "evolution",
  "Ex : comment": "Ex : comment",
  "Ex : field R02": "Ex : field R02",
  "Ex : Tresbolillos, row, tree": "Ex : Tresbolillos, row, tree",
  "expected_final_weight": "Expected final weight",
  "external_id": "External ID",
  "extra parameters": "extra parameters",
  "falhas carreadores": "Carrier Gaps",
  "falhas internas": "Internal Gaps",
  "falhas terraços": "Terraces Gaps",
  "falla": "Gaps",
  "fat performance": "Fat performance",
  "faults map": "Faults map",
  "fdn": "Fdn",
  "Fecha de vuelo": "Flight date",
  "Fecha Plantacion": "Plantation date",
  "Feddan": "Feddan",
  "fertilization strategy": "Fertilization strategy",
  "Fertilizer recommendation": "Fertilizer recommendation",
  "field": "field",
  "Field Name": "Field Name",
  "Field without geometry": "Field without geometry",
  "FIELD_FILTER": "Field filters",
  "field_name": "Field name",
  "FIELD_TEMPORAL_EVOLUTION": "Temporal evolution",
  "fields": "Fields",
  "Fields without geometries": "Fields without geometries",
  "File cannot be generated due to high amounts of clouds. Please select another date": "File cannot be generated due to high amounts of clouds. Please select another date",
  "File generator": "File generator",
  "File Generator": "File generator",
  "File name": "File name",
  "file type": "File type",
  "file-is-not-available-in-selected-language": "This file is not available in the selected language. Generated using english.",
  "Fill plantation date of current field, showing one year of historic data": "Fill plantation date of current field, showing one year of historic data",
  "Fill the field form": "Fill the field form",
  "Fill the form to apply for all our services": "Fill the form to apply for all our services",
  "filtered field": "filtered field",
  "filtered fields": "filtered fields",
  "Filters": "Filters",
  "final_weight": "Final weight",
  "fitopatógenos": "Phytopathogens",
  "Flight Slider": "Flight Slider",
  "FLIGHT_FILTER": "Temporal comparison",
  "flights type": "Flights type",
  "fmsavi": "FMSAVI",
  "for everyone": "for everyone",
  "for me": "for me",
  "forecasts": "Forecasts",
  "formulario de abonado": "Fertilization form",
  "formulario de dosis": "Dosage form",
  "fósforo foliar": "Foliar phosporus ",
  "fr": "Francium",
  "Fraction": "Fraction",
  "free": "free",
  "from": "From",
  "fruits_100_weight": "100 fruits weight",
  "fruits_actual1_weight": "Actual fruit weight",
  "Fuente de informacion": "source",
  "gallery": "gallery",
  "gauging date": "Sample date",
  "generation error": "generation error",
  "geometry": "geometry",
  "get a free trial": "get a free trial",
  "Get back to editor": "Get back to editor",
  "global files": "Download preprocessing, training and forecast PDF and CSV files",
  "global layer": "Global layer",
  "gm_cut_number": "Cut number ",
  "gm_data_generation": "Satellite date (weeks)",
  "gm_day_cycle": "Days of cultivation",
  "gm_harvest_estimated_date": "Estimated Harvest date (weeks)",
  "gm_hemav_atr_estimation": "TRS forecast",
  "gm_hemav_pol_estimation": "Polarization forecast",
  "gm_hemav_prodph_estimation": "Yield forecast",
  "gm_hemav_realprodph_lastseason": "Actual yield from previous season",
  "gm_hemav_sac_estimation": "Sucrose forecast",
  "gm_luvi_date_generation": "Satellite date - LUVI (weeks)",
  "gm_luvi_day_cycle": "Cultivation days (LUVI)",
  "gm_luvi_mean": "Vegetative vigour (NDVI-LUVI)",
  "gm_luvi_ndvi_std": "Variability (NDVI-LUVI)",
  "gm_lws_mean": "Water Stress (LWS)",
  "gm_ndmi_date_generation": "Satellite date - NDMI (weeks)",
  "gm_ndmi_day_cycle": "Cultivation days (NDMI)",
  "gm_ndre_date_generation": "Satellite date - NDRE (weeks)",
  "gm_ndre_day_cycle": "Cultivation days (NDRE)",
  "gm_sar_biomass_cat": "Categorical biomass (SAR)",
  "gm_season_label": "Season",
  "gm_sub_type_name": "Variety",
  "gm_type_name": "Crop",
  "go to field": "Go to field",
  "grid": "grid",
  "harvest date": "Harvest date",
  "harvest planning": "Harvest planning",
  "HARVEST_PLANNING_TOOL": "Harvest planning tool",
  "HARVEST_PREDICTION": "Harvest prediction",
  "Hectare": "Hectare",
  "Hemav Default": "Hemav Default",
  "HEMAV Default": "HEMAV Default",
  "Here, you can play with the preview of your different layers.": "Here, you can play with the preview of your different layers.",
  "hide": "hide",
  "Hide All Layers": "Hide All Layers",
  "hide filter list": "Hide filter list",
  "Hide Global Layer": "Hide Global Layer",
  "Hide Measure Tools": "Hide Measure Tools",
  "high": "High",
  "High contrast map": "High contrast map",
  "high level": "High",
  "high need": "High need",
  "higher clay content": "Higher clay content",
  "humedad de suelo - beta (%)": "Soil moisture - Beta (%)",
  "humedad vegetativa (ndmi)": "Vegetative Moisture (NDMI)",
  "Hybrid map": "Hybrid map",
  "Hybrid View": "Vista híbrida",
  "hydric status": "Hydric Status - NDMI",
  "hydric status drone": "Hydric status - Drone",
  "If foliar sampler haven't been made, the date you must enter is the current": "If foliar sampler haven't been made, the date you must enter is the current",
  "If you have two or more flights, you can compare them temporally using this tool.": "If you have two or more flights, you can compare them temporally using this tool.",
  "If you have undone an action, you can press this button to redo it.": "If you have undone an action, you can press this button to redo it.",
  "image": "image",
  "Impacto estimado": "Estimated impact",
  "In this tab, you can download the generated PDFs.": "In this tab, you can download the generated PDFs.",
  "individuals_num": "Individuals numbers",
  "infection": "Infection",
  "info": "Info",
  "Information": "Information",
  "informe": "Report",
  "integración con maquinaria": "Integration with machinery",
  "Invalid credentials": "Invalid credentials",
  "invalid date": "Invalid date",
  "Invalid email": "Invalid email",
  "invalid_geometry_error": "Invalid geometry",
  "invest": "Invest",
  "Irrigation recommendation": "Irrigation recommendation",
  "Irrigation type": "Irrigation type",
  "It is commonly adjusted to establish the amount of fertilizer or seeds to be applied per unit area": "It is commonly adjusted to establish the amount of fertilizer or seeds to be applied per unit area.",
  "item_num": "Item number",
  "Keep selection": "Keep selection",
  "label": "01.Label",
  "Label": "Label",
  "languaje": "Languaje",
  "last sample": "Last sample",
  "lat": "Latitude",
  "layers": "Layers",
  "layers estrés hídrico (lws)": "Layers water stress (LWS)",
  "layers status": "Layers status",
  "Legal limitation": "Legal limitation",
  "leptosphaeria_sacchari_g1": "leptosphaeria sacchari \"ring spot\" - g1",
  "leptosphaeria_sacchari_g2": "leptosphaeria sacchari \"ring spot\" - g2",
  "leptosphaeria_sacchari_g3": "leptosphaeria sacchari \"ring spot\" - g3",
  "leptosphaeria_sacchari_g4": "leptosphaeria sacchari \"ring spot\" - g4",
  "leptosphaeria_sacchari_g5": "leptosphaeria sacchari \"ring spot\" - g5",
  "letter only": "Only letter",
  "linear meters": "linear meters",
  "líneas de cultivo": "Crop Lines",
  "list": "list",
  "loading": "Loading",
  "location": "Location",
  "Log in": "Log in",
  "logout": "Logout",
  "lon": "Longitude",
  "looks like its taking too long": "looks like its taking too long",
  "low": "Low",
  "low level": "Low",
  "low need": "Low need",
  "lower clay content": "Lower clay content",
  "LUVI variability": "LUVI variability",
  "Machines Type": "Machines Type",
  "main_macronutrient": "Main macronutrient",
  "malas hierbas": "Weeds",
  "malas hierbas - hoja estrecha": "Weeds - Narrowleaf",
  "malas hierbas - hoja larga": "Weeds - Broadleaf",
  "malas hierbas - zonificado": "Weeds - Zoned",
  "malas hierbas shps": "Weeds SHPs",
  "malas hierbas shps - hoja estrecha": "Weeds SHPs - Narrowleaf",
  "malas hierbas shps - hoja larga": "Weeds SHPs - Broadleaf",
  "malas hierbas shps - zonificado": "Weeds SHPs - Zoned",
  "maleza": "Weeds",
  "Manage report": "Manage report",
  "max 2 years": "2 years max",
  "max comparable fields": "Max comparable fields",
  "maximo de variacion": "Maximum variation",
  "máximo vigor dos meses (ndvi)": "Maximum Vigor two months (NDVI)",
  "máximo vigor un año (ndvi)": "Maximum Vigor one year (NDVI)",
  "Maximun cloud coverage": "Maximun cloud coverage",
  "mean": "Mean",
  "Measures tool tutorial": "Tutorial: Measures tool",
  "Measures tools": "Measure tool",
  "media de faltas": "Mean Crop Gaps",
  "medias": "Medias",
  "medio pot prod": "Medium Pot Prod",
  "medium": "Medium",
  "medium clay content": "Medium clay content",
  "medium level": "Medium",
  "medium_weight_bigSize": "Mean weight big fruits",
  "medium_weight_mediumSize": "Mean weight medium fruits",
  "medium_weight_smallSize": "Mean weight small fruits",
  "Mensual limit": "Mensual limit",
  "Message": "Message",
  "METEOROLOGICAL_STATISTICS": "Meteorological statistics",
  "mode": "Mode",
  "model type": "Model type",
  "Move the slider": "Move the slider",
  "msavi": "MSAVI",
  "N_BUNCHES": "Number of bunches",
  "Name": "Name",
  "nameless field": "Field without a name",
  "Natural Breaks (Jenks)": "Natural Breaks (Jenks)",
  "ndre": "NDRE",
  "ndvi": "NDVI",
  "ndvi real": "NDVI Real",
  "ndvi-curvas de nivel": "NDVI-Level Lines",
  "Network request failed": "Connection error",
  "new sample": "new sample",
  "New sample created.": "New sample created.",
  "NITRO": "Nitrogen",
  "nitrogen chlorophyll (ndre)": "Nitrogen-Chlorophyll (NDRE)",
  "nitrogen_assimilable": "Assimilable nitrogen",
  "nitrógeno foliar": "Foliar nitrogen",
  "Nivel de estres": "Hidro stress level",
  "no data": "No Data",
  "no data available": "No data available yet",
  "no data with current selection": "No data with current selection",
  "No fields selected": "No fields selected",
  "no fields to clear": "No fields to clear",
  "no_date": "No date",
  "NO_DELIVERABLES_IMAGES_MESSAGE": "There are no images for this day.",
  "Not a user yet ?": "Not a user yet ?",
  "not enough data to display this chart": "Not enough data to display this chart",
  "Not supported by browser": "Not supported by your browser",
  "notes": "notes",
  "number only": "Number only",
  "Numero vuelo": "Flight count",
  "observations": "remarks",
  "offline access": "offline access",
  "offline mode": "Offline mode",
  "oil_yield": "Oil yield",
  "ok": "Ok",
  "Once you are done, click this button to save the changes.": "Once you are done, click this button to save the changes.",
  "only-classes-3-and-mode-fixed-is-valid": "Only 'Hemav Default' mode and '3' classes are valid parameters",
  "open": "open",
  "Open": "Open",
  "Open selection": "Open selection",
  "open temporal comparison": "Open temporal comparison",
  "open_selected": "Open selected",
  "optimize": "Optimize",
  "or": "or",
  "Order by a - z": "Order by a - z",
  "Order by distance to the field": "Order by distance to the field",
  "Order by z - a": "Order by z - a",
  "ourDeviation": "Hemav's deviation",
  "ourProductionEstimation": "Hemav's production estimation",
  "Parcela": "Field",
  "Password": "Password",
  "pb": "Pb",
  "pcd": "PCD",
  "pcd arbol": "PCD arbol",
  "pcd interpolado": "Interpolated PCD",
  "pcd row": "PCD Row",
  "pdf": "PDF",
  "pending": "pending",
  "pending_field_map_info_confirm_delete_field_text": "Are you sure you want to delete this field?",
  "pendingFields_missingGeo_message": "Some fields could not be created Please add geometry to them.",
  "pendingFields_serverError_message": "An error occurred from the server. Please try again.",
  "perimeter": "perimeter",
  "phospohorus": "Phospohorus",
  "phosporus": "Phosporus (ppm)",
  "photo": "photo",
  "photoid": "Photo ID",
  "photoId": "Photo",
  "picture_path": "Picture",
  "plague": "Plague",
  "planned dosage": "Planned dosage",
  "Plant emitters": "Plant emitters",
  "plantation date": "Plantation date",
  "Plantation date": "Plantation date",
  "plantation_end_date_range": "Plantation date range",
  "plantation_start_date_range": "Plantation date range",
  "Plantations": "Plantations",
  "plants": "Plants",
  "Plants distance": "Plants distance",
  "plants_density_range": "Plant density range",
  "Please wait or order one to have data to visualized.": "Program a flight to obtain deliverables for that field",
  "Please, choose a field variety": "Please, choose a field variety",
  "Please, choose the field owner": "Please, choose the field owner",
  "plural selected": "selected",
  "POL": "Polarization",
  "Polygon drawing": "Polygon drawing",
  "posible falla": "Possible gaps",
  "posible maleza": "Possible weeds",
  "positive number only": "Positive number only",
  "positive number only and minor 100": "Positive number only and minor 100",
  "pot. horticolar 90": "pot. horticolar 90",
  "pot. otros cultivos 150": "pot. otros cultivos 150",
  "pot. otros cultivos 90": "pot. otros cultivos 90",
  "potasio foliar": "Foliar potassium",
  "potencial productivo histórico (ndvi)": "Historical production potential (NDVI)",
  "precio": "Price",
  "PRED_TECH": "PredTech",
  "Predefined ranges by HEMAV which are generally appropriate for the majority of fields": "Predefined ranges by HEMAV which are generally appropriate for the majority of fields",
  "prediction": "Prediction",
  "preprocessing": "Preprocessing",
  "Press this button to add a new field.": "Press this button to add a new field.",
  "Pressing this button lets you go back to the field view. ": "Press this button to go back to field view.",
  "Preview": "Preview",
  "price Kg": "Price  per kg",
  "primer vuelo": "First flight",
  "PROD": "Yield by surface unit",
  "producción esperada": "Estimated Yield",
  "product Kg": "Product Kg",
  "product Kg is mandatory": "Product kg is mandatory",
  "Production estimate": "Production estimate",
  "production estimate (units)": "Production estimate (Kg/Ha)",
  "profile": "Profile",
  "protein": "Protein",
  "puccinia_sp_g1": "puccinia sp \"roya\" - g1",
  "puccinia_sp_g2": "puccinia sp \"roya\" - g2",
  "puccinia_sp_g3": "puccinia sp \"roya\" - g3",
  "puccinia_sp_g4": "puccinia sp \"roya\" - g4",
  "puccinia_sp_g5": "puccinia sp \"roya\" - g5",
  "Quick start with Azure": "Quick start with Azure",
  "Random fertilization": "Random fertilization",
  "Random seedling": "Random seedling",
  "Range": "Range",
  "Ranges adjusted to the index": "Ranges adjusted to the index",
  "Ranges of the same size will be generated according to the minimum and maximum values of the image. In cases where the image is very homogeneous, it is likely that no differentiation will be seen.": "Ranges of the same size will be generated according to the minimum and maximum values of the image. In cases where the image is very homogeneous, it is likely that no differentiation will be seen.",
  "realProduction": "Real production",
  "Recipients": "Recipients",
  "recomendación abonado dosificado": "Fertilization Dose Prescription",
  "recomendación abonado zonificado": "Zoned fertilisation recommendation",
  "Recomendacion de riego": "Irrigation advice",
  "recomendación riego": "Irrigation recommendation",
  "redo": "redo",
  "Redo Action": "Redo Action",
  "Related date": "Related date",
  "relative nitrogen": "Relative nitrogen",
  "Rendimiento graso ( % )": "fat performance ( % )",
  "report": "Report",
  "Report sent correctly": "Report sent correctly",
  "report type": "Type",
  "Report type": "Report type",
  "report_type_action_description": "Proposal of treatments or actions to be applied",
  "report_type_annotation_description": "Diagnostic: Analysis in the field",
  "report_type_inspection_description": "Analysis from LAYERS (its closed once its analyzed)",
  "report_type_result_description": "Analysis of the results of the solution or treatment applied.",
  "reporte agronómico": "Agronomical Report",
  "Reported by": "Reported by",
  "Reported fields": "Reported fields",
  "reports manager": "Reports",
  "required": "required",
  "Required": "Required",
  "Requires 3 characters minimun": "Requires 3 characters minimun",
  "Reset": "Reset",
  "resiembra": "Re-sowing",
  "resolution": "SHP resolution (m)",
  "resolution (only SHP)": "Resolution (only for SHP)",
  "retención de agua": "Water retention",
  "retry": "retry",
  "rgb": "RGB",
  "richness": "Richness",
  "riego zonificado": "Zoned Irrigation",
  "riesgo malherbología": "Weed Risk",
  "Ruler": "Ruler",
  "SAC": "Sucrose per surface unit",
  "saccharose estimate": "Saccharose Estimate",
  "sample": "Sample",
  "sample type": "type",
  "Sample was deleted.": "Sample was deleted.",
  "Sample was successfully modified.": "Sample was successfully modified.",
  "sample_creation_date": "Creation date",
  "samples": "Samples",
  "satélite": "Satellite",
  "Satellite button tutorial": "Tutorial: Satllite view",
  "Satellite map": "Satellite map",
  "Satellite map 2": "Satellite map 2",
  "Satellite View": "Satellite view",
  "save": "Save",
  "Save All": "Save all",
  "Save field": "Save field",
  "save_": "save",
  "search": "Search",
  "Search": "Search",
  "season": "season",
  "Season label": "Season label",
  "season name": "season name",
  "SEASON_EVOLUTION": "Season Evolution",
  "Sector": "Sector",
  "sectores target": "Target sectors",
  "See? Now, clicking the button again lets you go back to the satellite view.": "See? Now, clicking the button again lets you go back to the satellite view.",
  "seguimiento_cultivo.xlsx": "seguimiento_cultivo.xlsx",
  "segundo vuelo": "Second flight",
  "Select": "Select",
  "select a crop and sample": "Select a crop and sample",
  "select all": "Select all",
  "Select all": "Select all",
  "select at least one field": "Select at least one field",
  "select fields by": "Select fields by",
  "select fields by variability range": "select fields by variability range",
  "select fields to open temporal comparison": "Select fields to open temporal comparison",
  "select one": "Select one",
  "select one customer please": "Select one customer please",
  "select one subtype please": "Select one subtype please",
  "select one type please": "Select a crop type please",
  "select sample base layer and date": "select sample's base layer and date",
  "select sample location": "select sample location",
  "select season": "select season",
  "selected": "selected",
  "Selected fields names": "Selected fields",
  "Selected fields samples": "Selected fields samples",
  "Selection includes too much information, try selecting a shorter date range": "Selection includes too much information, try selecting a shorter date range",
  "Send": "Send",
  "Send report": "Send report",
  "settings": "Settings",
  "Share fields report": "Share fields report",
  "show all": "show all",
  "Show All Layers": "Show All Layers",
  "Show current season only": "Show current season only",
  "show fields with no data": "Show fields with no data",
  "show filter list": "Show filter list",
  "Show Global Layer": "Show Global Layer",
  "Show Measure Tools": "Show Measure Tools",
  "shp-tooltip": "These numbers can be modified according to the usage needs and are associated with each geometry class in the exported SHP attribute table. Commonly adjusted to establish the quantity of fertilizer or seeds that will be applied per unit of surface.",
  "singular selected": "selected",
  "Slice tool only works on Polygons and Circle": "Slice tool only works on Polygons and Circle",
  "Slider Tool": "Slider Tool",
  "soil": "Soil",
  "soil_type": "Soil type",
  "sólo para la generación de SHP": "For SHP generation only",
  "Sort": "Sort",
  "sort by date": "sort by date",
  "Start date": "Start date",
  "static layers": "static layers",
  "Statistics": "Statistics",
  "status": "Status",
  "stem_num": "Stem number",
  "Streets distance": "Streets distance",
  "Subtype": "Subtype",
  "Suc/Ha": "Suc/Ha",
  "sum_of_bases": "Sum of bases",
  "Superficie ( Ha )": "Superficie ( Ha )",
  "Superficie Sector ( Ha )": "Superficie Sector ( Ha )",
  "surfaceUnit": "Surface unit",
  "switch between all samples and current season samples": "switch between all samples and current season samples",
  "Switch to hybrid map": "Switch to hybrid map",
  "Switch to Mapbox": "Switch to satellite2 map",
  "Switch to satellite map": "Switch to satellite map",
  "Switch to Terrain map": "Switch to terrain map",
  "target date": "Target date",
  "target multiparcela": "Multifield Target",
  "tell us": "Tell us",
  "Temporal Comparison tutorial": "Tutorial: Temporal Comparison",
  "Terrain map": "Terrain map",
  "textura del suelo": "Soil texture",
  "The date should be in DD/MM/YYYY format": "The reference date must be valid in DD/MM/YYYY format",
  "The distance must be in meters": "The distance must be in meters",
  "the extra parameters will be removed": "the extra parameters will be removed",
  "the following layers are no longer available in offline mode, download again if necessary": "the following layers are no longer available in offline mode, download again if necessary",
  "The production must be numeric/decimal": "The production must be numeric/decimal",
  "The right column shows the total amount to be applied according to the value entered per class, and the total per plot at the bottom": "The right column shows the total amount to be applied according to the value entered per class, and the total per plot at the bottom.",
  "The sample location must be inside one of the fields.": "The sample location must be inside one of the fields.",
  "There are no samples to download": "There are no samples to download",
  "There is no operated flight on this field.": "There is no operated flight on this field",
  "Thermal Accumulation": "Thermal Accumulation",
  "These numbers can be modified according to application needs and are associated with each geometry in the attribute table of the exported SHP": "These numbers can be modified according to application needs and are associated with each geometry in the attribute table of the exported SHP.",
  "This action will delete the field and all its related data": "This action will delete the field and all its related data",
  "this action will modify your field selection": "This action will modify your field selection",
  "this action will open field view and lose current field selection": "This action will open field view and drop the current field selection",
  "This button allows you to draw lines or polygons to measure a distance or area.": "This button allows you to draw lines or polygons to measure a distance or area.",
  "This button allows you to switch to the hybrid view.": "This button allows you to switch to the hybrid view.",
  "This can take a long time": "This can take a long time",
  "This field is not downloaded.": "This field is not downloaded",
  "This tool lets you cut polygons. Use single clicks to draw the line and a double click to finish drawing.": "This tool lets you cut polygons. Use single clicks to draw the line and a double click to finish drawing.",
  "This tool lets you draw polygons. Try to draw a simple one. Make sure you close it!": "This tool lets you draw polygons. Try to draw a simple one. Make sure you close it!",
  "This tool lets you measure the distance between a set of points. Use single clicks to set the points and a double click to finish drawing.": "This tool lets you measure the distance between a set of points. Use single clicks to set the points and a double click to finish drawing.",
  "Those fields are mandatory": "Those fields are mandatory",
  "TIME_COMPARISON_DROP_ZONE": "Drop here...",
  "TINTA BARROCA": "BAROQUE INK",
  "TINTA FRANCISCA": "FRANCISCA INK",
  "TINTO": "RED",
  "TINTO CÃO": "RED CAO",
  "tipo de dosificacion": "Dosage type",
  "to": "To",
  "today": "today",
  "todos": "All",
  "tons of cane - annual vision": "Tons of Suggar Cane - Annual Vision",
  "Total": "Total",
  "total production": "Total production (Kg)",
  "total_fruits_bigSize": "Total big fruits",
  "total_fruits_mediumSize": "Total medium fruits",
  "total_fruits_smallSize": "Total small fruits",
  "total_weight": "Total weight",
  "total_weight_bigSize": "Total weight big fruits",
  "total_weight_canes": "Total weight canes",
  "total_weight_mediumSize": "Total weight medium fruits",
  "total_weight_smallSize": "Total weight small fruits",
  "TOURIGA FRANCA": "TOURIGA FRANCA",
  "training": "Training",
  "TRINCADEIRA": "TRINCADEIRA",
  "Try our mobile app for a better experience": "Try our mobile app for a better experience",
  "type": "Crop",
  "Type": "Crop Type",
  "type here your notes": "type here your notes",
  "Type your text": "Type your text",
  "type_sampler": "Type sampler",
  "undo": "undo",
  "Undo Action": "Undo Action",
  "unidades": "Units",
  "unitSystem": "Unit system",
  "Unknown": "Unknown",
  "unnamed field": "Unnamed field",
  "unsaved changes will be lost": "unsaved changes will be lost",
  "update sample": "update sample",
  "update_draft": "update draft",
  "updated": "Updated",
  "Use this buttons to select which layer to compare.": "Use this buttons to select which layer to compare.",
  "Use this tool to compare two flights temporally. To start, drag one of the flights into the one you want to compare it with.": "Use this tool to compare two flights temporally. To start, drag one of the flights into the one you want to compare it with.",
  "Use this tool to freely move the created polygons.": "Use esta ferramenta para mover livremente os polígonos criados.",
  "use_layers_web_to_create_large_fields": "Use Layers Web for creating fields larger than 10 000 ha",
  "used": "used",
  "Username": "Username",
  "users": "Users",
  "Value": "Value",
  "variabilidad multiparcela": "Multifield Variability",
  "variabilidad parcela (vra)": "Variable Rate Area (VRA)",
  "variability": "Variability",
  "Variedad": "Sub crop",
  "vegetative growth": "Vegetative Growth",
  "vegetative growth per day": "Per Day Accumulated Vegetative Growth",
  "vegetative growth variability": "Vegetative Growth Variability",
  "Ver detalles": "View details",
  "very high": "Very high",
  "very high level": "Very high",
  "very low": "Very low",
  "very low level": "Very low",
  "view": "view",
  "vigor": "VIGOR",
  "vigor a nivel árbol": "vigor / plant",
  "vigor acumulado dos meses (ndvi)": "Accumulated vigor two months (NDVI)",
  "vigor acumulado un año (ndvi)": "Accumulated vigor one year (NDVI)",
  "vigor vegetativo": "Vegetative vigor",
  "vigor vegetativo - pcd": "PCD - Vegetative Vigour",
  "vigor vegetativo (ndvi)": "Vegetative vigor (NDVI)",
  "vigor vegetativo (ndvi) - fases tempranas": "Vegetative Vigor (NDVI) - Early Stages",
  "vigor zonificado": "Vigorous zoning",
  "VINHÃO": "VINHÃO",
  "Visibility": "Visibilidade",
  "Vulnerable area": "Vulnerable area",
  "wait": "wait",
  "warning": "Warning",
  "warning map": "Warning map",
  "Water level": "Water level",
  "We are processing the data for your flight. Results will be available soon.": "We are processing the data of the flight. Please wait to have all images",
  "weeds area": "Weeds area",
  "week": "Week",
  "weight_1": "Weight 1",
  "weight_2": "Weight 2",
  "weight_3": "Weight 3",
  "weight_4": "Weight 4",
  "weight_5": "Weight 5",
  "With this tool you can draw circles. Click to place the center, and click again to choose the radius.": "With this tool you can draw circles. Click to place the center, and click again to choose the radius.",
  "With this tool, you can draw a polygon with 3 or more points to measure its area.": "With this tool, you can draw a polygon with 3 or more points to measure its area.",
  "without warning": "Without warning",
  "Wrong credentials": "Invalid credentials",
  "year": "Year",
  "years": "Year",
  "You can change here the information of the new field.": "You can change here the information of the new field.",
  "You can move this bar to compare the layers temorally.": "You can move this bar to compare the layers temorally.",
  "You can only compare dates from the same sources": "You can only compare dates from the same sources",
  "You can use this button at any moment to undo your last actions.": "You can use this button at any moment to undo your last actions.",
  "You can use this slider to change the opacity of the selected layer.": "You can use this slider to change the opacity of the selected layer.",
  "You cannot create field because you don't have any customers. Please ask us for adding ones": "You cannot create field because you don't have any customers.Please ask us to add some.",
  "You must enter a decimal": "You must enter a decimal",
  "You must enter an integer": "You must enter an integer",
  "You must select an irrigation type": "You must select an irrigation type",
  "You must type a field name": "You must type a field name",
  "You must type a text": "You must type a text",
  "Your field has been successfully deleted.": "Your field has been successfully deleted",
  "Your LAYERS version has expired. Click to update.": "Your LAYERS version has expired. Click to update."
}
